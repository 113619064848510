.spacedInline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.despCommentInline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.imageInlineStyle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px;
}

.spacedInlineCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* min-height: 100px; */
}

.inlineAlt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 25px;

  /* marginLeft: 20, marginTop: 20 */
}

.iconInline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-bottom: 15px; */
  margin-left: 25px;
  /* margin-top: 25px; */
  /* marginLeft: 20, marginTop: 20 */

}

.subjectLine {
  display: flex;
  flex-direction: row;
  padding: 0px 20px 20px 20px;
}

.htmlText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-bottom: 20px;  */
  margin-left: 25px;
  margin-top: 20px;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

.inline {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
}

.activityType {
  color: #00AACC;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 0;
  font-weight: 550;
  font-size: 18px;
  /* margin-top: 8px; */
  margin-left: 20px;

}

.desc {
  color: #8D8D8D;
  font-size: 16px;
  margin-bottom: 20px;
  white-space: pre;
  white-space: break-spaces;
  font-weight: 548;

  /* margin-left: 20px; */
}

.descDate {
  color: #8D8D8D;
  font-size: 18px;
  margin-bottom: 15px;
  white-space: pre;
  white-space: break-spaces;
  line-height: 25px;
  font-weight: 548;

}

.descHtml {
  /* color: #8D8D8D; */
  /* font-size: 18px; */
  /* margin-bottom: 15px; */
  white-space: pre;
  white-space: break-spaces;
  /* line-height: 25px; */
  /* font-weight: 548; */
  /* margin-left: 20px; */
}

.taskDesc {
  color: #8D8D8D;
  font-size: 18px;
  margin-bottom: 15px;
  white-space: pre;
  white-space: break-spaces;
  margin-left: 10px;
  line-height: 25px;
  font-weight: 548;
}

.infoTitle {
  color: #303030;
  font-size: 16px;
  margin-right: 20px;

  @media only screen and (max-width: 500px) {
    font-size: 15px;
  }
}

.infoTitleUser {
  color: #303030;
  font-size: 18px;
  margin-right: 5px;
}

.commentDiv {
  margin-right: 30px;
  margin-bottom: 30px;
  font-size: 16px;

  @media only screen and (max-width: 500px) {
    font-size: 13px;
  }
}

.activityDate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  /* margin-left:20px; */
  margin-top: 10px;
  margin-right: 30px;
  /* marginLeft: 20, marginTop: 20 */
}

.iconDiv {
  background-color: #E0F8F8;
  border-radius: 8px;
  margin: auto;
  ;
  /* margin-left: 15px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  /* padding-bottom: 20px; */
  cursor: pointer;

}

.icon {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.columnNameDiv {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: #303030;
  margin-left: -20px;
}


@media (min-width: 768px) {
  .vertical-line {
    border-right: 1px solid #ccc;
    padding-right: 10px;
    margin-left: 20px;
  }

  #container {
    position: relative;
    height: 100px;
    width: 100px;
    border: 1px solid #999;
  }

  #div1,
  #div2 {
    position: absolute;
  }

  #div1 {
    top: 0
  }

  #div2 {
    bottom: 0;
  }
}

.secondCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 5%;
  margin-right: 2%;
  margin-top: 3%;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .secondCol {
    margin-left: 4%;
    margin-right: 2%;
    margin-top: 2%;
  }

  .columnNameDiv {
    margin-left: 0px;
  }

  .emailInfoDiv {
    margin-left: 0px;
  }
}

@media(max-width:500px) {
  .secondCol {
    /* margin-left: 4%;
    margin-right: 2%; */
    margin-top: 2%;
  }
}

@media (max-width: 480px) {
  .secondCol {
    margin-top: 1%;
  }
}

.sub_header {
  font-size: 14px;
  margin-left: 20px;
  margin-top: 3px;
  padding-top: 0;
  font-weight: normal;
  color: #485473;
}

.emailStatusDiv {
  padding-left: 45px
}

.showEmailDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: -20px;
  margin-right: -10px;
  width: "100%";
}

@media only screen and (max-width: 500px) {

  .showEmailDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px
  }

  .emailStatusDiv {
    padding-left: 25px
  }
}

.wrapDiv {
  display: flex;
  flex-wrap: wrap;

}