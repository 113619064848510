/* @import @colors from "../../Utility/colorFactory";  */

.listInlineView {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid;
  border-color: #D0D0D0;
}

.studentKeyStyle {
  /* color: colors.v2_Cerulean; */
  font-size: 13px;
  margin: 0;
  /* background-color: colors.v2_base; */
  padding: 10px;
  text-transform: capitalize;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.studentKeyStyleWithBorder {
  color: #00AACC;
  font-size: 16px;
  margin: 0;
  /* background-color: colors.v2_base; */
  padding: 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  text-transform: capitalize;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.studentValueStyleAlt {
  font-size: 16px;
  margin: 0;
  padding-left: 10px;
  /* font-weight: 600; */
  color: #5B5B5B;
  overflow-wrap: break-word;
  font-weight: 500
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #5B5B5B;
  color: #5B5B5B
}

.spacedInline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.activeButton {
  margin-top: 8px;

}

.status-div {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  align-items: center;
  margin-bottom: 30px;
}

.actionButtonsView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.followUpDivLine {
  border-left: 1px solid #00AACC;
  height: 125px;
}

@media only screen and (max-width: 1450px) {
  /* .actionButtonsView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  } */

  .followUpDivLine {
    height: 200px;
  }
}

@media only screen and (max-width: 980px) {
  .followUpDivLine {
    height: 0px;
    border: none
  }
}

@media only screen and (max-width: 950px) {
  .actionButtonsView {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1350px) {
  /* .activeButton {
    margin-top: 42px;
    margin-left: -117px;
  } */


  .status-div {
    display: flex;
    /* flex-direction: row; */
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
  }
}


@media only screen and (max-width: 1100px) {
  .profileImage {
    width: 80px;
    height: 80px;
  }

  .status-div {
    display: flex;
    /* flex-direction: row; */
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
  }

  .activeButton {
    margin-top: 8px;
    margin-left: 0px
  }

}

@media only screen and (max-width: 764px) {
  .activeButton {
    margin-top: 8px;
    margin-left: 0px
  }

  .infoDiv {
    margin-top: 24px;
  }

  .status-div {
    display: flex;
    /* flex-direction: row; */
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
  }

}

.nextFollowUp {
  color: #00AACC;
  font-size: 18px;
}

.value {
  color: #8D8D8D;
  font-size: 18px;
  font-weight: 500px;
  margin-left: 10px;


  /* margin-right: 30px; */
}

.valueUpper {

  color: #8D8D8D;
  font-size: 18px;
  /* margin-ri: 45px;  */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow-wrap: break-word;

}

.name {
  color: #5B5B5B;
  font-size: 18px;
  text-transform: capitalize;
  line-height: 27px;
  font-weight: 500px;
  margin-bottom: 4px;

}

.profileImageAvtar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

.contactInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  margin-top: 7px;
}

.contactInfo1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  margin-top: -2px;
}

.contactInfo1 img {
  margin-right: 5px;
}

.contactInfo img {
  margin-right: 5px;
}

.emailText {
  display: flex;
  align-items: center;
  color: #8D8D8D;
  font-size: 18px;
  overflow-wrap: break-word;

  margin-top: 1px;
}

.actionSpacedline {
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  justify-content: center
}

@media (min-width: 768px) {
  .vertical-line {
    border-right: 1px solid #ccc;
    padding-right: 10px;
    color: #00AACC;
  }

}

.followUp {
  /* position: absolute; */
  width: 185px;
  height: 27px;
  left: 330px;
  top: 240px;
  font-weight: 500;
  font-size: 18px;
  color: #5B5B5B;
  margin-bottom: 15px;

}

.profileImageAvtar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

@media screen and (max-width: 1199px) {

  /* lg (large) screens */
  .profileImageAvtar {
    width: 120px;
    height: 120px;
  }
}

@media screen and (max-width: 991px) {

  /* md (medium) screens */
  .profileImageAvtar {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 767px) {

  /* sm (small) screens */
  .profileImageAvtar {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 575px) {

  /* xs (extra small) screens */
  .profileImageAvtar {
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {

  /* xl (extra large) screens */
  .profileImageAvtar {
    width: 150px;
    height: 150px;
  }
}